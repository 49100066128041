$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');
#not-the-same-store-modal-for-address .modal-dialog.modal-lg,
#not-the-same-store-modal-for-address-on-place-order .modal-dialog.modal-lg,
#not-the-same-store-modal .modal-dialog.modal-lg,
#modalReplaceOrderContinue .modal-dialog.modal-lg,
#modalReplaceOrderChangeAddress .modal-dialog.modal-lg,
#modalReplaceOrder .modal-dialog.modal-lg {
    max-width: 1000px;
}
#not-the-same-store-modal-for-address .modal-content,
#not-the-same-store-modal-for-address-on-place-order .modal-content,
#not-the-same-store-modal .modal-content,
#modalReplaceOrderContinue .modal-content,
#modalReplaceOrderChangeAddress .modal-content,
#modalReplaceOrder .modal-content {
    background: #F4F4F4;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    border: 0;
}
#not-the-same-store-modal-for-address button.close,
#not-the-same-store-modal-for-address-on-place-order button.close,
#not-the-same-store-modal button.close,
#modalReplaceOrderContinue button.close,
#modalReplaceOrderChangeAddress button.close,
#modalReplaceOrder button.close {
    position: absolute;
    z-index: 15;
    right: 30px;
    top: 30px;
    text-shadow: none!important;
    opacity: 1!important;
}
#not-the-same-store-modal-for-address .modal-header,
#not-the-same-store-modal-for-address-on-place-order .modal-header,
#not-the-same-store-modal .modal-header,
#modalReplaceOrderContinue .modal-header,
#modalReplaceOrderChangeAddress .modal-header,
#modalReplaceOrder .modal-header {
    border: 0;
    padding: 0;
}
#not-the-same-store-modal-for-address .modal-body p .highlight,
#not-the-same-store-modal-for-address-on-place-order .modal-body p .highlight,
#not-the-same-store-modal .modal-body p .highlight,
#modalReplaceOrderContinue .modal-body p .highlight,
#modalReplaceOrder .modal-body p .highlight {
    color: map-get($colors, 'color_6');
}
#not-the-same-store-modal-for-address .modal-body p,
#not-the-same-store-modal-for-address-on-place-order .modal-body p,
#not-the-same-store-modal .modal-body p,
#modalReplaceOrderContinue .modal-body p,
#modalReplaceOrder .modal-body p {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: map-get($colors, 'color_1');
    margin-top: 0;
    margin-bottom: 0;
}
#modalReplaceOrderChangeAddress .modal-body {
    padding: 110px 85px;
    align-items: center;
    display: flex;
    flex-direction: column;
}
#not-the-same-store-modal-for-address .modal-body,
#not-the-same-store-modal-for-address-on-place-order .modal-body,
#not-the-same-store-modal .modal-body,
#modalReplaceOrderContinue .modal-body,
#modalReplaceOrder .modal-body {
    padding: 80px 40px 40px 40px;
    align-items: center;
    display: flex;
    flex-direction: column;
}
#not-the-same-store-modal-for-address .modal-footer,
#not-the-same-store-modal-for-address-on-place-order .modal-footer,
#not-the-same-store-modal .modal-footer,
#modalReplaceOrderContinue .modal-footer,
#modalReplaceOrder .modal-footer {
    border: 0;
    padding: 0 0 30px 0;
    display: flex;
    justify-content: center;
}
#not-the-same-store-modal-for-address .modal-footer a,
#not-the-same-store-modal-for-address-on-place-order .modal-footer a,
#not-the-same-store-modal .modal-footer a,
#modalReplaceOrderContinue .modal-footer a,
#modalReplaceOrder .modal-footer a {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 10px 25px;
    border: 0;
    text-decoration: none!important;
    outline: none!important;
    margin: 0 10px;
    display: inline-flex;
}
#not-the-same-store-modal-for-address .modal-footer .btn-delete-cart,
#not-the-same-store-modal-for-address-on-place-order .modal-footer .btn-delete-cart,
#not-the-same-store-modal .modal-footer .btn-delete-cart,
#modalReplaceOrderContinue .modal-footer .btn-delete-cart,
#modalReplaceOrder .modal-footer .btn-delete-cart {
    background-color: map-get($colors, 'color_5');
}
#not-the-same-store-modal-for-address .modal-footer .btn-modify-address,
#not-the-same-store-modal-for-address-on-place-order .modal-footer .btn-modify-address,
#not-the-same-store-modal .modal-footer .btn-modify-address,
#modalReplaceOrderContinue .modal-footer .btn-modify-address,
#modalReplaceOrder .modal-footer .btn-modify-address {
    background-color: map-get($colors, 'color_6');
}
#modalReplaceOrderChangeAddress .location-pin-big-icon {
    background-image: url($themeVariantImgPath + '/homepage/location-pin-big-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 146px;
    height: 146px;
    display: flex;
}
#modalReplaceOrderChangeAddress .modal-body h5 {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: map-get($colors, 'color_1');
    margin-top: 30px;
    margin-bottom: 30px;
}
#modalReplaceOrderChangeAddress .address-section .address-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
#modalReplaceOrderChangeAddress .address-section .address-box div:first-child {
    border-radius: 15px;
}
#modalReplaceOrderChangeAddress .address-section .address-box div:nth-child(2) {
    border-radius: 15px 0 0 15px;
}
#modalReplaceOrderChangeAddress .address-section .address-box .address {
    display: inline-flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
    padding: 0 25px;
    position: relative;
}
#modalReplaceOrderChangeAddress .pin-location-icon {
    background-image: url($themeVariantImgPath + '/homepage/pin-location-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    display: flex;
    margin-right: 10px;
}
#modalReplaceOrderChangeAddress .address-section .address-box .address input {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: map-get($colors, 'color_1');
    height: 70px;
    border: 0;
    box-shadow: none!important;
    outline: none!important;
}
#modalReplaceOrderChangeAddress .address-section .address-box .number {
    display: inline-flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
    padding: 0 25px;
    width: 200px;
    position: relative;
}
#modalReplaceOrderChangeAddress .home-location-icon {
    background-image: url($themeVariantImgPath + '/homepage/home-location-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    display: flex;
    margin-right: 10px;
}
#modalReplaceOrderChangeAddress .address-section .address-box .number input {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: map-get($colors, 'color_1');
    height: 70px;
    border: 0;
    width: 110px;
    box-shadow: none!important;
    outline: none!important;
}
#modalReplaceOrderChangeAddress .address-section .address-box .address .sylius-validation-error,
#modalReplaceOrderChangeAddress .address-section .address-box .number .sylius-validation-error {
    display: none;
    position: absolute;
    bottom: -40px;
}
#modalReplaceOrderChangeAddress .address-section .address-box .btn-view-stores {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    background: map-get($colors, 'color_6');
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15), 0px 2px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0 15px 15px 0;
    padding: 12px 30px;
    border: 0;
    height: 70px;
    display: inline-flex;
    align-items: center;
    width: 250px;
}
#modalReplaceOrderChangeAddress .arrow-right-white-icon {
    background-image: url($imgPath + '/homepage/arrow-right-white-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    display: flex;
    margin-left: 12px;
}

@media (max-width: 767px){
    #not-the-same-store-modal-for-address .modal-body,
    #not-the-same-store-modal-for-address-on-place-order .modal-body,
    #not-the-same-store-modal .modal-body,
    #modalReplaceOrderContinue .modal-body,
    #modalReplaceOrder .modal-body {
        padding: 40px 25px 30px 25px;
    }
    #not-the-same-store-modal-for-address button.close,
    #not-the-same-store-modal-for-address-on-place-order button.close,
    #not-the-same-store-modal button.close,
    #modalReplaceOrderContinue button.close,
    #modalReplaceOrderChangeAddress button.close,
    #modalReplaceOrder button.close {
        right: 10px;
        top: 10px;
    }
    #not-the-same-store-modal-for-address .modal-footer,
    #not-the-same-store-modal-for-address-on-place-order .modal-footer,
    #not-the-same-store-modal .modal-footer,
    #modalReplaceOrderContinue .modal-footer,
    #modalReplaceOrder .modal-footer {
        padding: 0 10px 30px 10px;
        display: block;
        text-align: center;
        width: 100%;
    }
    #not-the-same-store-modal-for-address .modal-footer a,
    #not-the-same-store-modal-for-address-on-place-order .modal-footer a,
    #not-the-same-store-modal .modal-footer a,
    #modalReplaceOrderContinue .modal-footer a,
    #modalReplaceOrder .modal-footer a {
        margin: 5px 7px;
        font-size: 16px;
        line-height: 20px;
    }
    #modalReplaceOrderChangeAddress .modal-body {
        padding: 40px 20px;
    }
    #modalReplaceOrderChangeAddress .location-pin-big-icon {
        width: 100px;
        height: 100px;
    }
    #modalReplaceOrderChangeAddress .modal-body h5 {
        font-size: 20px;
        line-height: 25px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    #modalReplaceOrderChangeAddress .address-section .address-box {
        flex-direction: column;
    }
    #modalReplaceOrderChangeAddress .address-section .address-box .address {
        width: 100%;
        margin-bottom: 10px;
    }
    #modalReplaceOrderChangeAddress .address-section .address-box .number {
        border-radius: 15px;
        width: 100%;
        margin-bottom: 10px;
    }
    #modalReplaceOrderChangeAddress .address-section .address-box .btn-view-stores {
        border-radius: 15px;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }
    #modalReplaceOrderChangeAddress .address-section .address-box .address.error,
    #modalReplaceOrderChangeAddress .address-section .address-box .number.error {
        margin-bottom: 50px;
    }
}

body .pac-container {
    z-index: 6000!important;
}

.modal-body {
    &.with-height-scroll {
        overflow-y: auto;
        max-height: calc(100vh - 200px);
    }
}
